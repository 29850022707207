import { render, staticRenderFns } from "./Ad-Be.vue?vue&type=template&id=3327c2db&scoped=true&lang=pug&"
import script from "./Ad-Be.vue?vue&type=script&lang=js&"
export * from "./Ad-Be.vue?vue&type=script&lang=js&"
import style0 from "./Ad-Be.vue?vue&type=style&index=0&id=3327c2db&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3327c2db",
  null
  
)

export default component.exports