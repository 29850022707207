import { render, staticRenderFns } from "./TraceBack.vue?vue&type=template&id=84165efa&scoped=true&lang=pug&"
import script from "./TraceBack.vue?vue&type=script&lang=js&"
export * from "./TraceBack.vue?vue&type=script&lang=js&"
import style0 from "./TraceBack.vue?vue&type=style&index=0&id=84165efa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84165efa",
  null
  
)

export default component.exports