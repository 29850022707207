<template lang="pug">
  .hello
    br.fat-only
    h1 {{ sify('編創緣起') }}
    .ui.grid
      .one.column.row
        .column
          .ui.segment.container
            h3 前言：
            p {{ sify('補習班研發優質教材，除了自己教學用，是為了連鎖開店。我們研發優質教材，除了自己教學用，是為了公益。') }}
            h3 理念：
            p {{ sify('台灣國高中數學一直是學子頭痛的課題，許多人一到國中，就放棄數學。對於補習的孩子，往往考出了成績也未必真懂，更是壓力的重擔。') }}
            p {{ sify('又因補習風氣過度，國中老師常會以補習班教過為由，跳過基本概念，讓沒有補習的孩子，除非天資特別，往往跟不上進度。不懂久了，挫折累積，就逐漸放棄。') }}
            p
              | {{ sify('另一方面，由於追求適性教育，台灣的在家自學人數愈來愈多，台北市一年約有上千人，且還在不停成長。自學生迫切需要教學資源，不只是教材，還有知識的脈絡，讓他們能用自己的步調學習，而非暗中瞎闖。') }}
            p
              | {{ sify('課本的設計是為了搭配上課的講解，因此往往而有綱要較少脈絡，一般坊間所謂參考書也是假想學生已在課堂上學過脈絡，而把重點放在習題。這些材料對使用教材自學的學生，過於零碎。因而專門為自學情境設計的教材更有其必要。') }}
            p
              | {{ sify('因此，我們計畫用兩年的時間，創作一系列形成性教材，還有一本國中數學自學手冊，將上課的講解化諸文字，包含脈絡與學習方法，還有國中數學的大地圖，明列各單元之間的主從與先後關係，讓學習者可自我檢視、自訂進度，落實學生中心的教學理念。') }}
            p {{ sify('拜熱心的網友之賜，台灣的國中數學免費學習資源不少，在網路上可以找到很多，但是目前現在的網路資源有兩個瓶頸：') }}
            h4 1. 脈絡：
            p {{ sify('網上的資源，大多還是照著課本的單元，但是課本的單元是跳著上的，一下子代數、一下子幾何、一下子機率，除非全部學完，否則根本理不出頭緒。') }}
            p {{ sify('例如，當學習者學到幾何時，有興趣深入，他要花很多力氣才能找到下一個相關的單元，因為分在不同冊甚至不同年級。') }}
            p {{ sify('我們計劃透過自學手冊與其中的學習地圖，回歸學習的本質，以學生為中心，讓他可以掌握自己的進度，並自我檢視。') }}
            h4 2. 品質：
            p {{ sify('由於網路的方便，加上經費的考量，許多網路資源的品質其實不佳，有的甚至不如坊間的講義自修，更別說補習班的私藏教材了。') }}
            p {{ sify('我們計劃讓自由教材的品質，提高到不輸給補習班商業機密的私藏教材，這樣可以讓整體的水準提高，讓學習者真的能靠教材學會、學懂，並且喜歡數學。') }}
            h3 SWOT分析：
            h4 S優勢：
            p
              | {{ sify('朱佳仁老師與我，師事數學教授與數學教育家，台大數學系副教授朱建正先生多年，對學生中心的教學充份的理解。教學經驗皆有十年以上，教導過體制內外的學生和在家自學生，曾幫助許多特殊生、學障生、資優生重建興趣，領略數學之美，也協助過許多自學生訂定自學計劃與檢視。') }}
            h4 適性教學的專業
            h4 學生中心的專業
            h4 重建興趣的專業
            h4 自學指導的專業
            h4 W劣勢：
            p {{ sify('由於我們是教學專業，而非編輯專業，對於各種編輯軟體、繪圖軟體的使用，還有如何推廣，都是邊做邊學。') }}
            p
              | {{ sify('人力有限也是一個限制。我們只有兩個人合力研發，一般出版社都是百人以上。因為人力不足，本來想連高中數學一併開發，造福更多學子，但現在要等國中教材成功後，才能再找合作夥伴來一起設計高中的教材。') }}
            p {{ sify('此外，經費也是影響人力資源的重要因素。免費教材基本上無利可圖，為了維持生活收入，我們都必須花大量的時間備課、開課，無法專注於公益教材的開發。') }}
            h4 編輯軟體的經驗
            h4 推廣管道的局限
            h4 人力資源的局限
            h4 經費的局限
            h3 O機會點：
            p {{ sify('在家自學社群中，已有許多人提出類似的需求，希望有好教材、學習脈絡。') }}
            p {{ sify('邊遠地區的學校，也很缺好的免費教材，讓學生就算在學校不懂，也不去補習，還是可以靠教材與地圖，自學數學，且能學會、學懂、學得紮實而有興趣。') }}
            h3 Ｔ威脅：
            p
              | {{ sify('翻譯教材的想法是一個威脅。歐美的自學起步較早，加上地廣人多，社群較大，現在已累積許多好的教材。因此也有人考慮直接翻譯瑞典、美國或新加坡的自學教材，就不必自己研發。') }}
            p {{ sify('我們對台灣的教材資源提升當然樂見其成，但是對翻譯教材的投入可能會影響對本土教材的支持。') }}
            p {{ sify('雖然國外的教材品質也高，但本土教材有幾個難以取代的好處：') }}
            h4 本土化的舉例：
            p {{ sify('例如，我們用廟裡的筊杯來介紹機率基本觀念。翻譯教材舉的例子常和台灣學生的生活經驗無關，讓例子的力道打折扣。') }}
            h4 古典的舉例：
            p
              | {{ sify('例如，我們在介紹勾股定理時，用了&lt;周髀算經&gt;的圖，而非&lt;幾何原本&gt;的圖。一方面是幫助理解，二方面也是復古風，讓學生瞭解古中國也有高明的數學成就，數學不只是外國人的玩意。') }}
            h4 名詞解釋：
            p {{ sify('[統]是綜合大量的、[計]是計算，所以[統計]是[綜合大量資料的計算]') }}。像這樣的中文數學名詞解釋，在翻譯的教材中不可能出現。
            h4 流暢的語文：
            p {{ sify('我們形成性教材中許多文字填空，讓學生邊學邊想。翻譯的教材文字，除非譯者特別高明，否則文法和修辭上會有點怪怪，較難一氣呵成，尤其要修改文字填空的位置，相當不易。') }}
      .two.column.doubling.row
        .column
          .ui.segment
            h2 跟著教材思考  -  朱佳仁，自由數學共同發起人
            h3 為什麼要編教材？
            // img(src='/static/img/Chiajen001.jpg', width='50%')
            p
              | {{ sify('當初寫這份教材，是因為市面上找不到合適的自學教材，') }}
              b {{ sify('一般教科書太嚴謹、太生硬，很難讓人上手自學，而參考書又只是條列重點後，就是一堆例題。') }}
            p
              | {{ sify('剛好那時候父親幫康軒編了數學好小子這一套國小數學教材，以填充格讓學生能夠跟著解題思路走，就向宗浩提議合編一套國中數學教材，') }}
              b {{ sify('一樣以邊閱讀邊填充的方式，增加學生參與的機會，並且配合教材合開了混齡的數學自學課程，這是起點。') }}
            p
              | {{ sify('後來我們的共開了幾學期的自學課程，還參入數學遊戲的部份，變成「數學練與玩」，而這套教材也在學生學習的需要下，逐漸把國中各單元編寫完成，要感謝這門課程，讓我們在學生需要的催促下，能夠完成一個又一個的單元。') }}
            h3 教材的取向
            p
              | {{ sify('很多學生到了國中後，開始懷疑這些非日常生活所需的數學，為什麼還要學？我常跟學生說，腦袋像肌肉一樣，不用就會退化，抽象思考能力如果不藉由學習更深的數學，就無法鍛鍊，') }}
              b {{ sify('也許這些內容日後並不會用到，但是在學習的過程中，學到的解題思路與策略，卻是一輩子帶的走的能力。') }}
            p
              | {{ sify('這套教材也許並不如教科書一般嚴謹，但是') }}
              b
                | {{ sify('它希望讓學生感覺到好像有人陪著他一起想問題，並且經歷當年這些許許多多的前輩們，所面臨的掙扎、所採取的各種試驗，所以在過程中特別帶入與前後單元的連結，並配合「數學地圖」，讓學生體會到數學觀念並非孤島，而是一棵互想有連結、枝葉茂密的大樹。') }}
            h3 編創的考驗與開源釋出的想法
            p
              | {{ sify('編寫教材是數學教學者最大的考驗，在課堂上，一個經驗豐富的教學者可以即時反應學生的需要，但是在編寫教材中，要想像學生在做的時候，可能會有的反應，又要兼顧讓學習的材料能夠有邏輯的呈現出來。還有挑選或是設計有代表性的例題，讓學生能夠真正的在做這些題目中，學到東西。') }}
            p
              | {{ sify('把這份教材釋出就是希望能夠讓更多人一起跟著教材思考與學習，而教材是活的，不同使用者的回饋還會讓我們去修改內容，或是以其他方式來貼近使用者的需要。') }}
              b {{ sify('希望大家多多給我們回饋，並且享受一起思考、一起發現的快樂與成就。') }}
            iframe(width='90%', height='360', src='https://www.youtube.com/embed/6NhyCXJU-IQ?rel=0', frameborder='0', allowfullscreen='')
          .ui.segment.container
            p.fluid.text-center 本專案原始碼位於
              a(href="https://www.github.com/3dw/freemath", target="_blank", rel="noopener noreferrer")
                i.github.icon
                | https://www.github.com/3dw/freemath
        .column
          .ui.segment.container
            h2 教材不是萬能，沒教材萬萬不能  -  唐宗浩，自由數學共同發起人
            h3 為什麼要編教材？
            // img(src='/static/img/Bestian001.jpg', width='50%')
            p {{ sify('其實，一開始我和許多教育工作者一樣，只是在教自己班上的學生，有需要時，會自編一些學習單而已，沒想過會去編整套的教材。') }}
            p {{ sify('那時我總認為編整套的教材，是有人力、有財力的出版社，或是公益的基金會，才做的事。') }}
            p {{ sify('後來是因為教材不夠用，我為了要找一套，可以自學用的教材，去賣參考書的書店逛。') }}
            p
              b {{ sify('結果，我被看到的一切嚇到了。找了很久，找不到一本有完整交代觀念的。它們都把重點放在後面的練習題。') }}
            p {{ sify('這意味著，學生不能用這些教材自學，甚至無法邊學邊問，甚至在觀念還不清楚的情況下，就被埋入大量的習題之中。') }}
            p
              b {{ sify('這不是我要的。我要學生可以拿來自己學會的。') }}
            p {{ sify('事實上，很多學生在課堂上不會完全聽懂，聽懂也可能會忘，教材應該具有學習和複習的功能，尤其是先備知識有洞的學生，更需要適合程度的教材來補洞。') }}
            p
              | {{ sify('例如對一個「分數」概念不清的學生，你給他二十題分數的「計算」練習，並不能幫他澄清概念。很多人以為學生數學不好就是練習不夠，其實不一定，很多時候是觀念不清。練習也重要，但不能本末倒置。') }}
            p {{ sify('沒有完整引入觀念的教材，只能說是公式與題目集錦，只能當額外練習，完完全全不適合學生自學。') }}
            p
              b {{ sify('那時我十分沮喪。後來升起一個念頭：既然，我要的教材不存在，那就只好自己去編了。') }}
            h3 和佳仁老師合作
            p {{ sify('佳仁是我在教育界的前輩，也是我在小學時的理化老師，後來我在自主培力學園任教時，她也給過很多教學方面的指導。') }}
            p {{ sify('佳仁在自主培力學園請產假的那一學年，我大學還沒畢業，就接下了代課的任務。') }}
            p {{ sify('代佳仁的課，一開始壓力很大，因為佳仁的課很精彩，也很紮實，而且自主培力學園是挑戰很高的班級。') }}
            p
              | {{ sify('培力學園專收不想上學的國高中生，重建他們的學習動力。那時我第一次帶混齡、學生個別差異大，包含符號障礙、閱讀障礙、受過不當教學、受過霸凌的學生時，為了重新建立成功經驗、啟動思考實驗的勇氣與能力、讓學生重新喜歡數學，就用了全部的心思和能力。') }}
            p {{ sify('雖然學生的能力和態度進步明顯，但是很多在學校的考試上，仍沒有能激起成就感的成績進步。') }}
            p {{ sify('我原本是要他們拿學校課本來自己練。我以為在思考和學習能力進步後，看課本自修應該不難。') }}
            p
              b
                | {{ sify('但是，當我看到當前的課本編輯方式，我就瞭解為什麼他們沒辦法看課本自修。不要說困難的學生，一般生也很難看那種課本來自修。課本編輯的方式，和以前我中學時大大不同，竟然忽略讓人形成概念的「原始問題」，而是從名詞、符號、公式與例題講起。') }}
            p {{ sify('那樣的課本，完全只能在課堂中，當作一個索引和練習，非常不適合自己唸。') }}
            p
              b {{ sify('我也嘗試找過其他坊間的教材、參考書、包括體制外的教材…凡是一般家庭找得到的教材，我大概都接觸過。但是，都沒有一套既包含了課本的軸線，又適合自己唸的。') }}
            p {{ sify('正好那時佳仁回來，我們就商量自編「形成性教材」，也就是可以自修來形成概念，可以當課本用的教材。') }}
            p {{ sify('其實，如果不是佳仁決定一起合作，我自己一個人可能不敢著手去編。') }}
            h3 {{ sify('編教材的過程') }}
            p {{ sify('編一份教材的過程，並沒有想像中簡單。並不是把自己會的，寫出來就好。') }}
            p {{ sify('形成性教材，是把課堂上的引入、講述、探問、深化、綜合、練習等過程，用紙本呈現。') }}
            p
              | {{ sify('課堂上，同一個概念可以用三四種不同的角度切入，可以跟學生持續對話，直到他懂為止。教材就沒有辦法，同一個概念大約只能用一兩個例子和代表性的圖解，來做引入，所以設計的過程就必須格外小心。如果弄偏了，學生自己唸的時候就會看不懂。') }}
            p
              b {{ sify('還有要考量的就是文字的量。對於閱讀障礙的學生，不管內容這是什麼，字太多、排版太緊，就會沒辦法讀。') }}
              | {{ sify('既然教材要讓閱讀障礙的學生也能使用（當然難免還是會有些吃力），就必須在脈絡清楚的前提下，將字數精簡、將版面簡化、也不宜放太多花俏的裝飾。') }}
            p
              | {{ sify('對我來說，') }}
              b {{ sify('編一份搭配上課的輔助教材，大約要一個小時，但是編一份可以自修用的形成性教材，大約要一個星期。') }}
            p {{ sify('兩者的難度，是天壤之別。') }}
            h3 學習地圖
            p {{ sify('學習地圖原本的初始想法，其實只是一份打勾用的清單，把各單元依年段列上去。') }}
            p
              | {{ sify('後來決定嘗試用先備後續的關係，畫成有向圖、拉出學習地圖，上面也標註各單元被排入的年級，這樣學生不一定要依年段來唸，可以依主題領域去自修。有些學生喜歡深入掌握一個主題，例如幾何，先把國中各個幾何的單位弄通，再去碰代數，而不是依照課本進度，一個年級各碰一些。') }}
            p
              b {{ sify('有這樣一張地圖，無論是想照年級去自修，或是想照主題領域去自修的學生，都可以各得其所。') }}
            p {{ sify('等到編小學學習地圖時，發現這個結構不適用，因為小學很多都是交互相關、適合用浸泡式、整合在一起學習。') }}
            p {{ sify('而且小學的領域也不大適合完全自修，所以地圖的編法是以親子數學為基本，給家長看的。') }}
            p {{ sify('至於高中地圖，目前還沒有編得很完善。不過初步可以看出，高中的單元幾乎都是為了大學的數學做準備，有些是截取其中一小角來教，並不真的很必要。') }}
            p
              | {{ sify('像微積分、線性代數、機率、統計、離散數學都是這樣，被截取一角放入高中課程。因此在地圖上就標出它們原本來自哪裡，如果有興趣的學生，可以自己去找原初的知識領域，完整學起來，瞭解更高的整體，回頭來看被選入高中課程的片段，就會覺得有脈絡可循而比較簡單了。') }}
            h3 開源的想法
            p
              b {{ sify('事實上，一開始我對開源也很陌生，是不是作品白白讓人家拿去用？') }}
            p {{ sify('但是，後來我覺得，不必為了防止少數人可能濫用，就去阻止多數人善用它的機會。而且，這套教材如果一直留在我們自己的檔案庫中，很可能一直都是半成品。') }}
            p
              b {{ sify('那些沒編完的單元，尤其是高中部份，需要畫大量的圖解，其實不是只靠兩名各自有家庭、有工作的人，用課餘的時間，在接下來的三五年內，能夠完成的。') }}
            p {{ sify('如果開源出去，說不定會影響一些人加入協作吧？說不定會有其他的親子數學實踐家長，和適性教學的老師，參與一起開發吧？光是為了這個可能，就值得了。') }}
            p
              b {{ sify('另一個原因是最近收到愈來愈多的公益教材使用申請，回信也回得有點手軟，不如把放上網路，想用的人直接下載，') }}
              | {{ sify('討論和錯誤回報直接在Github平台上進行，也不必申請和等待了。') }}
            p {{ sify('很高興一開始分享，就收到了寬廣媽媽分享的親子數學互動記錄。這記錄對於剛開始從事親子數學活動的人，是很寶貴的。') }}
            p {{ sify('希望自由數學Freemath愈來愈豐富，不只有好的教材，也有好的教法，也有好的互動記錄。') }}
            p
              b {{ sify('然後，當好的教材不用錢，而且親師生都可以取得，大家就可以把力氣放在學習方法、教學方法等難以言詮的地方，而不會再為教材煩惱') }}
              | {{ sify('。早日脫離「沒教材萬萬不能」，早日進入「教材不是萬能」的境地，把著眼點放在學習、互動與創造上。') }}
</template>

<script>
import { sify } from 'chinese-conv';

export default {
  name: 'intro',
  props: ['si'],
  data() {
    return {};
  },
  methods: {
    sify(t) {
      if (this.si) {
        return sify(t);
      } else {
        return t;
      }
    },
  },
};
</script>

<style scoped>
.hello {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 20px;
}

.ui.grid .column {
  margin: 10px 0;
}

.ui.segment.container {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ui.segment h3 {
  color: #0056b3;
  font-weight: bold;
  margin-bottom: 10px;
}

.ui.segment p {
  margin-bottom: 15px;
  color: #555;
}

.ui.segment b {
  color: #0056b3;
  font-weight: bold;
}

.ui.segment a {
  color: #007bff;
  text-decoration: none;
}

.ui.segment a:hover {
  text-decoration: underline;
}

.ui.segment h4 {
  color: #0056b3;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
}

.ui.segment iframe {
  margin-top: 20px;
}

.ui.segment .fluid.text-center {
  text-align: center;
  margin-top: 20px;
}

.ui.segment .github.icon {
  margin-right: 5px;
}
</style>
